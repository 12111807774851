import classnames from 'classnames';
import React, { Component } from 'react';

import { imsformLogo, introBgImg } from '../../../constants/images';
import styles from './MobileIntroStyle.module.scss';

class IntroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section
        className={styles.mobile_intro_section}
        style={{ backgroundImage: `url(${introBgImg})` }}
      >
        <div className={styles.mobile_intro_container}>
          <div className={styles.mobile_intro_logo_area}>
            <div className={styles.mobile_intro_logo}>
              <img src={imsformLogo} className={styles.mobile_intro_logo} />
            </div>
          </div>
          <div className={styles.mobile_intro_title_area}>
            <div className={classnames([styles.mobile_intro_title, 'wow flipInX'])}>혁신적인</div>
            <div className={classnames([styles.mobile_intro_title, 'wow flipInX'])}>
              렌터카 전산 시스템
            </div>
            <div className={styles.mobile_intro_title_line} />
            <div className={classnames([styles.mobile_intro_title_bold, 'wow fadeInLeft'])}>
              당신의 완벽한
            </div>
            <div className={classnames([styles.mobile_intro_title_bold, 'wow fadeInRight'])}>
              사업 파트너,
            </div>
            <div className={classnames([styles.mobile_intro_title_bold, 'wow fadeInLeft'])}>
              IMS form
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default IntroSection;
