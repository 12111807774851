import classnames from 'classnames';
import React, { Component } from 'react';

import { serviceImg5 } from '../../../constants/images';
import styles from './MobileIntroStyle.module.scss';

class Section4 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <section className={styles.mobile_section4}>
        <div className={styles.mobile_section4_container}>
          <div className={styles.mobile_section4_area}>
            <div className={classnames([styles.mobile_section4_title_area, 'wow flipInX'])}>
              <div className={styles.mobile_section4_main_title}>본사에서 실시간</div>
              <div className={styles.mobile_section4_main_title}>지점 관리가 가능합니다</div>
            </div>
            <div className={classnames([styles.mobile_section4_image_area, 'wow fadeIn'])}>
              <img src={serviceImg5} />
              <div className={styles.mobile_section4_text_area}>
                <div className={styles.mobile_section4_text_title}>지점 및 차량 관리</div>
                <div className={styles.mobile_section4_text_content}>
                  지점의 차량과 본사의 차량을 한눈에 빠르게 확인해
                </div>
                <div className={styles.mobile_section4_text_content}>
                  차량의 유동성을 증대시키고, 실시간으로 지점
                </div>
                <div className={styles.mobile_section4_text_content}>
                  계약서를 확인 할 수 있어 빠른 청구가 가능합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section4;
