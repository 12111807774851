import classnames from 'classnames';
import React, { Component } from 'react';
import Slider from 'react-slick';

import { serviceImg1, serviceImg2, serviceImg3, serviceImg4 } from '../../../constants/images';
import styles from './MobileIntroStyle.module.scss';

class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  renderSlide = () => {
    const slideItems = [
      {
        title: MESSAGE.MOBILE_CONTRACT,
        image: serviceImg1,
        content: [
          MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT1,
          MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT2,
          MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT3,
          MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT4,
        ],
      },
      {
        title: MESSAGE.CAR_LICENSE_FACT_YN,
        image: serviceImg2,
        content: [MESSAGE.INTRO_CAR_LICENSE_CONTANT1, MESSAGE.INTRO_CAR_LICENSE_CONTANT2],
      },
      {
        title: MESSAGE.SEARCH_CAR_OLD,
        image: serviceImg3,
        content: [
          MESSAGE.INTRO_CAR_OLD_CONTANT1,
          MESSAGE.INTRO_CAR_OLD_CONTANT2,
          MESSAGE.INTRO_CAR_OLD_CONTANT3,
        ],
      },
      {
        title: MESSAGE.CLAIM_SYSTEM,
        image: serviceImg4,
        content: [MESSAGE.INTRO_CLAIM_SYSTEM_CONTACT1, MESSAGE.INTRO_CLAIM_SYSTEM_CONTACT2],
      },
    ];
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      beforeChange: (prev, next) => this.setState({ currentSlide: next }),
      customPaging: (index) => {
        return (
          <div className={styles.dots}>
            <div
              className={index === this.state.currentSlide ? styles.active_dot : styles.normal_dots}
            />
          </div>
        );
      },
    };

    return (
      <div className={classnames([styles.mobile_section3_image_area, 'wow fadeIn'])}>
        <Slider {...settings}>
          {slideItems.map((item, index1) => {
            return (
              <div key={index1}>
                <img src={item.image} />
                <div className={styles.mobile_section3_text_area}>
                  <div className={styles.mobile_section3_text_title}>{item.title}</div>
                  {item.content.map((content, index2) => {
                    return (
                      <div className={styles.mobile_section3_text_content} key={index2}>
                        {content}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  beforeChangeHandler = (currentSlide) => {
    this.setState({ currentSlide });
  };

  render() {
    return (
      <section className={styles.mobile_section3}>
        <div className={styles.mobile_section3_container}>
          <div className={styles.mobile_section3_area}>
            <div className={classnames([styles.mobile_section3_title_area, 'wow flipInX'])}>
              <div className={styles.mobile_section3_main_title}>핸드폰만 있으면</div>
              <div className={styles.mobile_section3_main_title}>어디서든 업무가</div>
              <div className={styles.mobile_section3_main_title}>가능합니다</div>
            </div>
            {this.renderSlide()}
          </div>
        </div>
      </section>
    );
  }
}

export default Section3;
