import React, { Component } from 'react';

import { btnAppstore, btnGoogleplay, outroBgImg } from '../../../constants/images';
import styles from './MobileIntroStyle.module.scss';

class MobileLinkSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section
        className={styles.mobile_link_section}
        style={{ backgroundImage: `url(${outroBgImg})` }}
      >
        <div className={styles.mobile_link_container}>
          <div className={styles.mobile_content_area}>
            <div className={styles.mobile_link_title}>INTELLIGENT</div>
            <div className={styles.mobile_link_title}>MOBILITY</div>
            <div className={styles.mobile_link_title}>SYSTEM</div>
            <div className={styles.mobile_link_content}>당신의 사업을 더욱 쉽고 편리하게,</div>
            <div className={styles.mobile_link_content}>지금 바로 IMS form을 만나보세요!</div>
          </div>
          <div className={styles.mobile_link_img_area}>
            <div>
              <img
                src={btnGoogleplay}
                onClick={() =>
                  window.open('https://play.google.com/store/apps/details?id=com.rencarform')
                }
              />
            </div>
            <div>
              <img
                src={btnAppstore}
                onClick={() => window.open(`https://itunes.apple.com/kr/app/IMS-form/id1413102300`)}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MobileLinkSection;
