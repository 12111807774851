import React, { Component } from 'react';

import styles from './MobileIntroStyle.module.scss';

class AppDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goDownloadPage = () => {
    if (/Android/i.test(navigator.userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.rencarform');
    } else if (/iPhone|iPad/i.test(navigator.userAgent)) {
      window.open(`https://itunes.apple.com/kr/app/IMS-form/id1413102300`);
    }
  };

  setButtonStyle = () => {
    if (this.props.scrollY > 10) {
      return styles.mobile_app_btn_fix_wrap;
    }

    return styles.mobile_app_btn_float_wrap;
  };

  render() {
    return (
      <div className={this.setButtonStyle()}>
        <div onClick={this.goDownloadPage}>앱 다운로드</div>
      </div>
    );
  }
}

export default AppDownload;
