import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import AppDownload from './AppDownload';
import FooterSection from './FooterSection';
import IntroSection from './IntroSection';
import styles from './MobileIntroStyle.module.scss';
import MobileLinkSection from './MobileLinkSection';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';

@withRouter
@inject('authStore')
@observer
class MobileIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollDirectionUp: null,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();

      wow.init();
    }

    this.setState({ scrollY: window.scrollY });
    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleScroll(e));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const window = e.currentTarget;

    if (this.prev > window.scrollY) {
      this.setState({
        scrollY: window.scrollY,
        scrollDirectionUp: 'up',
      });
    } else if (this.prev < window.scrollY) {
      this.setState({
        scrollY: window.scrollY,
        scrollDirectionUp: 'down',
      });
    }

    this.prev = window.scrollY;
  }

  render() {
    return (
      <div className={styles.mobile_intro_wrap}>
        <IntroSection />
        <Section2 scrollY={this.state.scrollY} scrollDirection={this.state.scrollDirectionUp} />
        <Section3 />
        <Section4 />
        <MobileLinkSection ua={this.props.ua} history={this.props.history} />
        <FooterSection />
        <AppDownload scrollY={this.state.scrollY} />
      </div>
    );
  }
}

export default MobileIntro;
