import React, { Component } from 'react';

import MobileFooter from '../../../components/Footer/MobileFooter';
import styles from './MobileIntroStyle.module.scss';

class FooterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className={styles.mobile_footer_section}>
        <MobileFooter />
      </section>
    );
  }
}

export default FooterSection;
