import classnames from 'classnames';
import React, { Component } from 'react';

import { serviceImg6 } from '../../../constants/images';
import styles from './MobileIntroStyle.module.scss';

class Section5 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <section className={styles.mobile_section5}>
        <div className={styles.mobile_section5_container}>
          <div className={styles.mobile_section5_area}>
            <div className={classnames([styles.mobile_section5_title_area, 'wow flipInX'])}>
              <div className={styles.mobile_section5_main_title}>업체 지원 사업으로</div>
              <div className={styles.mobile_section5_main_title}>함께 성장합니다</div>
            </div>
            <div className={classnames([styles.mobile_section5_image_area, 'wow fadeIn'])}>
              <img src={serviceImg6} />
              <div className={styles.mobile_section5_text_area}>
                <div className={styles.mobile_section5_text_title}>업체 지원 사업</div>
                <div className={styles.mobile_section5_text_content}>
                  렌터카 업체 간 차량을 사고팔 수 있는 양수도 게시판,
                </div>
                <div className={styles.mobile_section5_text_content}>업체의 신용등급 파악 후</div>
                <div className={styles.mobile_section5_text_content}>
                  가산점을 부여해 진행하는 할부 금융 사업
                </div>
                <div className={styles.mobile_section5_text_content}>
                  특별한 금액에 신차를 판매하고 있는 신차 판매 등
                </div>
                <div className={styles.mobile_section5_text_content}>
                  IMS form은 함께 성장할 수 있게 솔루션을
                </div>
                <div className={styles.mobile_section5_text_content}>개발하고 공유합니다.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section5;
